export const saveToSessionStorage = (key, data) => {
  try {
    const serialisedState = JSON.stringify(data)
    sessionStorage.setItem(key, serialisedState)
  } catch (e) {
    console.warn(e)
  }
}

export const getFromSessionStorage = (key) => {
  try {
    const serialisedState = sessionStorage.getItem(key)
    return serialisedState ? JSON.parse(serialisedState) : null
  } catch (e) {
    console.warn('Error parsing session storage:', e)
    return null
  }
}
